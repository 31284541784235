'use strict';
import * as styles from '../css/.module/ladel_overview.css';

import panoramaIconImg from '../assets/panoramaicon.svg';

import * as Utils from './utils.js';
import * as Constants from './constants.js';
import ContextMenu from './context_menu.js';

import $ from 'jquery';
import * as d3 from 'd3';
import * as TWEEN from '@tweenjs/tween.js';


export default class LadelOverview {
  #target;
  #vessel;
  #guid;
  #footer;
  #positionMarkerTween;
  #svg;
  #vesselSvgPt;
  #positionMarkerTweenValue = -10;
  #brickInfoIndicator;
  #contextMenu;
  #showVesselDiameter;


  #showContextMenu;

  constructor(target, vessel, { footer = null, showContextMenu = false, showVesselDiameter = false}) {

    this.#target = target;
    this.#vessel = vessel;
    this.#guid = Utils.guid();
    this.#footer = footer;
    this.#showContextMenu = showContextMenu;
    this.#showVesselDiameter = showVesselDiameter;


 
    this.#target.innerHTML =
      ` <svg id="vesselSVG${this.#guid}" width="100%" height="100%" viewBox="0, 0, 100, 100">
                  
                  <defs id="defs">
                    <clipPath clipPathUnits="userSpaceOnUse" id="vessel_clip_path">
                      <use href="#generated_vessel_outline" x="0" y="0" width="100%" height="100%" />
                    </clipPath>

                    <g id="svgLug">
                      <image x="-1.56" y="-5.3" width="4" height="12" xlink:href="${panoramaIconImg}" />
                    </g>
                  </defs>


                  <g id="vesselOutline"></g>
                  <g id=svgVesselRegions></g>
                  <g id="svgVesselComponents"> </g>
                  <g id="selectedPositionMarker">
                    <line id="selectedPositionLine" x1="0" y1="-5" x2="0" y2="5" stroke="black" stroke-width=0.2
                      clip-path="url(#vessel_clip_path)" stroke-linecap="round" pointer-events="none">
                    </line>
                    <text id="selectedPositionText" x="0.2" y="0" font-size=0.7 color="888"
                      pointer-events="none"></text>
                  </g>
                </svg> 
                <div class="${styles.brickInfoIndicator}" id="brickInfoIndicator${this.#guid}"></div>
      `;

    if (this.#showContextMenu) {
      const menuItems = [
        {
          content: 'Open Panorama Image at position',
          events: {
            click: (_event) => this.openPanoramaAtPosition(this.#contextMenu.clientX, this.#contextMenu.clientY)
          }
        },
        {
          content: 'Open scan at position',
          events: {
            click: (_event) => this.openScanAtPosition(this.#contextMenu.clientX, this.#contextMenu.clientY)
          }
        },

        { content: 'Open ovality measurement' },
        // {
        //   content: `${deleteIcon}Delete`,
        //   divider: 'top' 
        // }
      ];

      this.#contextMenu = new ContextMenu({
        target: '#svgVesselRegions',
        mode: 'light',
        menuItems
      });
      this.#contextMenu.init();
    }
    this.#svg = document.getElementById(`vesselSVG${this.#guid}`);
    this.#brickInfoIndicator = document.getElementById(`brickInfoIndicator${this.#guid}`);

    this.#svg.setAttribute('viewBox', '-50, 0, 100, 100');
    this.setupVesselProfile();
    this.setUpEventListeners();

  }



  setupVesselProfile() {

    const vesselHeight = this.#vessel.vesselProfile.slice(-1)[0][0];
    const convert_x = val => val * 100 / 360;
    const convert_y_pos = val => ((vesselHeight - val) * 100 / vesselHeight);
    const convert_y_height = val => (val * 100 / vesselHeight);


    const regionsOfInterest = this.#vessel.regionsOfInterest; 
    
    const spliRegionsofInterest = regionsOfInterest.map ((elem, i) => Utils.splitRegion(elem, i)).flat();

    d3.select(this.#svg).select('#svgVesselRegions')
      .selectAll('rect')
      .data(spliRegionsofInterest)
      .join('rect')
      .attr('x', dta => convert_x(dta.angle.start))
      .attr('y', dta => convert_y_pos(dta.height.top))
      .attr('width', dta => convert_x(dta.angle.end - dta.angle.start))
      .attr('height', dta => convert_y_height(dta.height.top - dta.height.bottom))
      .attr('fill', (dta,i) => dta.color||Utils.selectColor(dta.region_id))
      .attr('class', styles.svgRegionOfInterest)
      .attr('id', (dta, i) => 'svg-region-' + i)
      .attr('region_id', dta => dta.region_id)
      .attr('pointer-events', 'none')
      .on('click', (event) => this.vesselSVGClick(event));

    d3.select(this.#svg).select('#svgVesselRegions')
      .selectAll('text')
      .data(spliRegionsofInterest)
      .join('text')
      .attr('x', dta => convert_x((dta.angle.start + dta.angle.end)/2))
      .attr('y', dta => convert_y_pos((dta.height.top + dta.height.bottom)/2))
      .attr('class', styles.svgRegionLabel)
      .text(dta => dta.name);
      


    d3.select(this.#svg).select('#vesselOutline')
      .append('rect')
      .attr('width', '100')
      .attr('height', '100')
      .attr('x', -50)
      .attr('y', 0)
      .attr('id', 'generated_vessel_outline')
      .on('mousemove', (event) => this.vesselSVGMouseMove(event))
      .on('mouseout', (event) => this.vesselSVGMouseOut(event));


    this.#vesselSvgPt = this.#svg.createSVGPoint();
  }


  vesselSVGMouseMove(event) {
    let loc = Utils.cursorPoint(event.clientX, event.clientY, this.#vesselSvgPt, this.#svg);
    this.showPositionIndicator(loc);
    this.#vessel.setHoveredPosition(new Utils.Location(loc.x));

    if (this.#footer) {
      this.#footer.innerHTML = `Current: ${loc.x.toFixed(1)} m`;
    }

  }


  vesselSVGMouseOut(_event) {
    this.#vessel.setHoveredPosition(null);

    this.#brickInfoIndicator.classList.remove(styles.show);
  }


  vesselSVGClick(event) {
    let loc = Utils.cursorPoint(event.clientX, event.clientY, this.#vesselSvgPt, this.#svg);
    this.#vessel.setSelectedPosition(new Utils.Location(loc.x));
  }


  onSelectedPositionChange(event) {

    let selectedPosition = event.detail.new_position.x;

    d3.select(this.#svg).select('#selectedPositionMarker')
      .transition()
      .duration(500)
      .attr('transform', `translate(${(selectedPosition).toFixed(2)} 0)`);

    d3.select(this.#svg).select('#selectedPositionText').text(`${(selectedPosition).toFixed(2)} m`);


    let id = this.vessel.getBrickIdAtPosition(selectedPosition);
    let previousRegion = null;

    if (id != previousRegion) {
      if (previousRegion >= 0) {
        $('#brickRegionInfoElem' + previousRegion).hide();
      }

      $('#brickRegionInfoElem' + id).fadeIn('normal', function () {
        // Animation complete
      });

      previousRegion = id;
    }


    this.#positionMarkerTween = new TWEEN.Tween(this.#positionMarkerTweenValue)
      .to({ x: selectedPosition }, 500)
      .easing(TWEEN.Easing.Quadratic.InOut)
      .start();


  }


  showPositionIndicator(loc) {
    let posInfoStr = '';
    let brick = this.#vessel.getBrickRegionAtPosition(loc);
    if (brick) {
      posInfoStr += `<p class="${styles.hoverBrickInfoElem}">${brick.attributes.Brand}</p>
      <p class="${styles.hoverBrickInfoElem}">${brick.attributes.Comment}</p>
      <p class="${styles.hoverBrickInfoElem}"> Installed: ${brick.attributes.Installed} </p>`;

      try {
        let installedDate = new Date(brick.attributes.Installed);
        if (!isNaN(installedDate)) {
          let currentDate = new Date();
          let months = (currentDate.getFullYear() - installedDate.getFullYear()) * 12;
          months -= installedDate.getMonth();
          months += currentDate.getMonth();
          months = months <= 0 ? 0 : months;
          posInfoStr += `<p class="${styles.hoverBrickInfoElem}"> Age: ${months} months </p>`;
        }
      }
      catch (e) {

      }
    }

    if (this.#showVesselDiameter) {

      posInfoStr += `<p class="${styles.hoverBrickInfoElem}"> Diameter: ${ (this.#vessel.getVesselRadiusAtPosition(loc.x)*2000).toFixed(0)} mm </p>`;


    }

    this.#brickInfoIndicator.style.top = `${event.clientY}px`;
    if (window.innerWidth / 2 > event.clientX) {
      this.#brickInfoIndicator.style.left = `${event.clientX + 10}px`;
    }
    else {
      this.#brickInfoIndicator.style.left = `${event.clientX - this.#brickInfoIndicator.clientWidth - 10}px`;
    }
    this.#brickInfoIndicator.classList.add(styles.show);
    this.#brickInfoIndicator.innerHTML = posInfoStr;

  }


  onAcceptableThicknessChange(_event) {
  }

  openPanoramaAtPosition(x, y) {
    let loc = Utils.cursorPoint(x, y, this.#vesselSvgPt, this.#svg);
    Utils.openInNewTab(`/panoramaimage-scan-view/${this.#vessel.currentScan}?meter=${loc.x}`);
  }



  openScanAtPosition(x, y) {

    let loc = Utils.cursorPoint(x, y, this.#vesselSvgPt, this.#svg);
    Utils.openInNewTab(`/scanview/${this.#vessel.currentScan}/?meter=${loc.x}`);


  }

  setUpEventListeners() {
    this.#vessel.addEventListener('LES_SELECTED_KILN_LOCATION_CHANGE', this.onSelectedPositionChange);
    this.#vessel.addEventListener('LES_ACCEPTABLE_THICKNESS_CHANGE', this.onAcceptableThicknessChange);


    // this.#vessel.addEventListener('resize', this.onResize);


    // this.#target.addEventListener('click', this.onClick);
    // this.#target.addEventListener('mosemove', this.onMouseMove);
    // this.#target.addEventListener('mouseout', this.onMouseOut);
    //this.#target.addEventListener('mousemove', (event) => this.onMouseMove(event));
    //this.#target.addEventListener('mouseout', (event) => this.onMouseOut(event));
  }


}
