
export default class Scatter {

  
  //from scatter.py
  // file_header = [
  //   self.magic,
  //   self.version,
  //   self.unit_0.value,
  //   self.unit_1.value,
  //   self.unit_location.value,
  //   self.nan_replace,
  //   self.dim_0_min,
  //   self.dim_0_max,
  //   self.dim_1_min,
  //   self.dim_1_max,
  //   self.location_min,
  //   self.location_max]

  #unit0;
  #unit1;
  #unitLocation;
  #invalidDepthVal;
  #dim0Min;
  #dim0Max;
  #dim1Min;
  #dim1Max;
  #locationMin;
  #locationMax;
  #data;
  #path;
  #pointData = [];

  #isFetching = false;
  #nextLocationToFetch = null;

  #preCalcAngle=false;
  #angleData = null;

  #locationPositions;


  #StartInMemory = Number.MIN_SAFE_INTEGER;
  #EndInMemory = Number.MIN_SAFE_INTEGER;
  #MaxMemoryUsage = 1000 * 1000;





  #dataReceived(arrayBuffer) {
    // A lot of things can be done here for instance caching the data in memory - at least cropping it around the actual requested positions - currently taking it based on positions in the file
    let int16data = new Int16Array(arrayBuffer, 0, arrayBuffer.byteLength / 2);
    this.#pointData = new Array(int16data.length / 3 );
    for (var i = 0; i < int16data.length; i += 3) {
      this.#pointData[i/3] = [int16data[i] / 1000, int16data[i + 1] / 1000, int16data[i + 2] / 1000];
    }

    if (this.#preCalcAngle) {
      this.#angleData = this.#pointData.map((point, idx) => [Math.atan2(-point[2], -point[1]), idx]).sort((a,b) => a[0]- b[0]);
    }


  }

  #headerReceived(arrayBuffer, callback) {

    const byteArray = new Uint8Array(arrayBuffer);
    if (byteArray.length < 25) {
      //wont fit the header nor for new file format nor relevant data for old format
      throw new Error('scatter header too short');
    }
    // Oscar Fogelbergs magic file format header  - allows the checking of endianness as well so in the futurue we can support that
    if ((byteArray[0] == 0xF0 && byteArray[1] == 0x0F) || (byteArray[0] == 0x0F && byteArray[1] == 0xF0)) {
      this.littleEndian = (byteArray[0] == 0xF0 && byteArray[1] == 0x0F);
      let header = new Int16Array(arrayBuffer, 2, arrayBuffer.byteLength / 2 - 1);
      //check version
      if (header[0] != 1) {
        throw new Error('scatter file version not supported ');
      }



      const DimensionType = Object.freeze({
        ANGLE : 0,
        MM : 1,
        X_M : 2,
        Y_M : 3,
        Z_M : 4,
        CM : 5,
        DM: 6,
      });
        
      const ValueType = Object.freeze({
        DISTANCE : 0,
        WEAR  : 1,
        THICKNESS : 2
      });
    
      
      const DEFAULT_DIMENSION_UNIT_CONVERSIONS =[
        10, 
        1,
        1000,
        1000,
        1000,
        10,
        100, // meters in short is mm for scatter
      ];
    
    

      const unit_0 = header[1];
      const unit_1 = header[2];
      const unit_location = header[3];
      const nan_replace = header[4];
      const dim_0_min = header[5];
      const dim_0_max = header[6];
      const dim_1_min = header[7];
      const dim_1_max = header[8];
      const dim_location_min = header[9];
      const dim_location_max = header[10];


      const numberOfLocationPositions = header[11];
      this.#locationPositions = new Array(numberOfLocationPositions);
      for (let i = 0; i < numberOfLocationPositions; i++) {
        const basePos = (12 + i * 3);
        this.#locationPositions[i] = [header[basePos], header[basePos + 1], header[basePos + 2]];
      }

      this.#unit0 = unit_0;
      this.#unit1 = unit_1;
      this.#unitLocation = unit_location;

      this.#dim0Min = dim_0_min / DEFAULT_DIMENSION_UNIT_CONVERSIONS[this.#unit0];
      this.#dim0Max = dim_0_max / DEFAULT_DIMENSION_UNIT_CONVERSIONS[this.#unit0];
      this.#dim1Min = dim_1_min / DEFAULT_DIMENSION_UNIT_CONVERSIONS[this.#unit1];
      this.#dim1Max = dim_1_max / DEFAULT_DIMENSION_UNIT_CONVERSIONS[this.#unit1];
      this.#locationMin = dim_location_min / DEFAULT_DIMENSION_UNIT_CONVERSIONS[this.#unitLocation];
      this.#locationMax = dim_location_max / DEFAULT_DIMENSION_UNIT_CONVERSIONS[this.#unitLocation];


      this.#invalidDepthVal = nan_replace;

      // notify that header has been fetched
      callback(this);


    }



  }
  constructor(path, {preCalcAngle = false} = {}) {
    this.#path = path;

    this.#preCalcAngle = preCalcAngle;
  }


  fetchHeader(callback) {
    fetch(this.#path)
      .then(response => response.arrayBuffer())
      .then(data => this.#headerReceived(data, callback));

  }

  async getLocation(sliceStart, sliceEnd) {
    this.#nextLocationToFetch = [sliceStart, sliceEnd];
    let startPointNumber = Number.MAX_SAFE_INTEGER;
    let endPointNumber = 0;
    let data = null;
    if (!this.#isFetching) {
      sliceStart = this.#nextLocationToFetch[0];
      sliceEnd = this.#nextLocationToFetch[1];
      this.#nextLocationToFetch = null;
      let accumulated = 0;

      if (this.#unitLocation == 2) {
        sliceStart = sliceStart * 1000;
        sliceEnd = sliceEnd * 1000;
      }
      else if (this.#unitLocation == 5){
        sliceStart = sliceStart * 100;
        sliceEnd = sliceEnd * 100;
      }
      


      for (const locationPosition of this.#locationPositions) {
        if (locationPosition[0] >= sliceStart && startPointNumber > accumulated) {
          startPointNumber = accumulated;

        }

        if (locationPosition[1] >= sliceEnd) {
          endPointNumber = accumulated += locationPosition[2];
          break;

        }
        accumulated += locationPosition[2];
      }
      const response = await fetch(this.#path + '.data', {
        headers: {
          'content-type': 'multipart/byteranges',
          'range': `bytes=${startPointNumber * 3 * 2}-${endPointNumber * 3 * 2}`, //three elements per position, 2 bytes per int16
        },
      }
      );
      data = await response.arrayBuffer();
      this.#nextLocationToFetch = null;
      this.#isFetching = false;
      this.#dataReceived(data);
    }





  }





  get unit0() { return this.#unit0; }
  get unit1() { return this.#unit1; }
  get unitLocation() { return this.#unitLocation; }
  get invalidDepthVal() { return this.#invalidDepthVal; }
  get dim0Min() { return this.#dim0Min; }
  get dim0Max() { return this.#dim0Max; }
  get dim1Min() { return this.#dim1Min; }
  get dim1Max() { return this.#dim1Max; }
  get locationMin() { return this.#locationMin; }
  get locationMax() { return this.#locationMax; }

  get data() { return this.#data; }
  get path() { return this.#path; }

  get pointData() { return this.#pointData; }
  get angleData() { return this.#angleData; }

  get memorySize() {
    return 0;
  }
}
